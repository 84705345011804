import { useEffect, useState } from "react";
import useSWR, { SWRConfiguration } from "swr";

import useAuth from "hooks/useAuth";
import useCurrentOrganisationIdContext from "hooks/useCurrentOrganisationIdContext";

import JsonApi, { JsonApiParams, JsonApiResponse } from "services/JsonApi";

type Headers = Record<string, string>;

export interface GetResponse<T> {
  data?: T;
  headers: Headers;
  mutate: (data: T) => void;
  isLoading: boolean;
  isValidating: boolean;
  error: any;
  page?: number;
  totalPages?: number;
  perPage?: number;
  totalCount?: number;
}

interface PerformResponse<Params, Data> {
  perform: (attrs?: Params) => Promise<{ data: Data; headers: Headers } | null>;
  error?: any;
  isLoading: boolean;
}

export const useGetResource = <T>(
  url: string | null,
  params?: JsonApiParams,
  baseUrl?: string | null,
  options: SWRConfiguration = { revalidateOnFocus: false }
): GetResponse<T> => {
  const { getAccessToken } = useAuth();
  const { currentOrganisationId } = useCurrentOrganisationIdContext();

  let requestUrl = url;

  if (requestUrl?.includes("undefined") || requestUrl?.includes("//")) {
    requestUrl = null;
  }

  const fetcher = async () => {
    const accessToken = await getAccessToken();
    const api = new JsonApi({ baseUrl, accessToken, currentOrganisationId });

    try {
      const { data, headers, meta }: JsonApiResponse<T> = await api.get(
        requestUrl!,
        {
          params,
        }
      );
      return { data, headers, meta };
    } catch (err) {
      throw `Error loading, ${err}`;
    }
  };

  const waitingForFilterParams =
    params?.filter &&
    Object.values(params?.filter).some((filter) => filter === undefined);

  const waitingForSwrParams = Boolean(
    (params?.page?.per_page && !params?.page?.page) ||
      !requestUrl ||
      waitingForFilterParams
  );

  const {
    data,
    isValidating,
    error,
    mutate: swrMutate,
  } = useSWR(
    waitingForSwrParams ? null : `${requestUrl}:${JSON.stringify(params)}`,
    fetcher,
    options
  );

  const mutate = (mutateData: any) => {
    swrMutate({
      data: mutateData,
      headers: data?.headers!,
      meta: data?.meta,
    });
  };

  useEffect(() => {
    // force revalidation when user switches organisation
    if (currentOrganisationId && swrMutate) {
      swrMutate();
    }
  }, [currentOrganisationId, swrMutate]);

  const isLoading = isValidating && !data;

  return {
    data: data?.data,
    headers: data?.headers!,
    mutate,
    isLoading,
    isValidating,
    error,
    page: data?.meta?.page,
    totalPages: data?.meta?.total_pages,
    perPage: data?.meta?.per_page,
    totalCount: data?.meta?.total_count,
  };
};

const usePerformResource = <T1, T2>(
  method: "post" | "patch" | "delete",
  url: string | null,
  params?: JsonApiParams,
  baseUrl?: string,
  type?: string
): PerformResponse<T1, T2> => {
  const [error, setError] = useState<any>(null);
  const [isLoading, setLoading] = useState(false);

  const { getAccessToken } = useAuth();
  const { currentOrganisationId } = useCurrentOrganisationIdContext();

  let requestUrl = url;

  if (requestUrl?.includes("undefined") || requestUrl?.includes("//")) {
    requestUrl = null;
  }

  const perform = async (attrs?: T1) => {
    const accessToken = await getAccessToken();
    const api = new JsonApi({ baseUrl, accessToken, currentOrganisationId });

    try {
      setError(null);
      setLoading(true);

      const { data, headers }: JsonApiResponse<T2> = type
        ? await api.request(requestUrl!, type!, attrs, method, { params })
        : await api[method](requestUrl!, attrs, { params });

      return { data, headers };
    } catch (err) {
      setError(err); // TODO: Reject promise?
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { perform, error, isLoading };
};

export const useCreateResource = <T1, T2>(
  url: string | null,
  params?: JsonApiParams,
  baseUrl?: string,
  type?: string
) => usePerformResource<T1, T2>("post", url, params, baseUrl, type);

export const useUpdateResource = <T1, T2>(
  url: string | null,
  params?: JsonApiParams,
  baseUrl?: string
) => usePerformResource<T1, T2>("patch", url, params, baseUrl);

export const useDeleteResource = (
  url: string | null,
  params?: JsonApiParams,
  baseUrl?: string
) => usePerformResource<string, object>("delete", url, params, baseUrl);

export const useRequestResource = <T1, T2>(
  url: string | null,
  type: string,
  params?: JsonApiParams,
  baseUrl?: string
) => usePerformResource<T1, T2>("post", url, params, baseUrl, type);
